import { buildMediaQueries } from '@rategravity/marketing-components';
import React from 'react';
import styled from 'styled-components';
import SquiggleDividerInvertedSVG from '../../images/squiggle-divider-inverted.svg';
import SquiggleDividerSVG from '../../images/squiggle-divider.svg';

export const SquiggleDivider = () => (
  <div aria-hidden style={{ display: 'flex', justifyContent: 'center', margin: '50px 0' }}>
    <img src={SquiggleDividerSVG} alt="" height="10px" style={{ maxWidth: '270px' }} />
  </div>
);

export const SquiggleDividerInverted = ({ maxWidth }: { maxWidth?: string }) => (
  <div aria-hidden style={{ display: 'flex', justifyContent: 'center', margin: '50px 0' }}>
    <img
      src={SquiggleDividerInvertedSVG}
      alt=""
      style={{ maxWidth: maxWidth ? maxWidth : '1300px' }}
    />
  </div>
);

const LargeWrapper = styled.div`
  ${buildMediaQueries('smallAndDown', {
    display: 'none'
  })}
`;

const SmallWrapper = styled.div`
  ${buildMediaQueries(['medium', 'large'], {
    display: 'none'
  })}
`;
export const ResponsiveSquiggleDivider = ({ maxWidth }: { maxWidth?: string }) => (
  <>
    <SmallWrapper>
      <SquiggleDivider />
    </SmallWrapper>
    <LargeWrapper>
      <SquiggleDividerInverted maxWidth={maxWidth} />
    </LargeWrapper>
  </>
);

import { buildMediaQueries, colors } from '@rategravity/marketing-components';
import { createOwnUpComponent } from '@rategravity/own-up-component-library-legacy';
import React from 'react';
import styled from 'styled-components';
import { GroupedReviews } from '../../../components/reviews/grouped-reviews';
import { findYourBestMortgageReviews } from '../../../data/content/find-your-best-mortgage-mktg02/reviews';
import TopGraphicSVG from '../../../images/icons/green-carat-down.svg';
import { SquiggleDivider } from '../../squiggle-divider';
import { landingPageImages as images } from '../../static-images/old/landing-page';
import { HeadlineReview } from './headline-review';
import { FindYourBestMortgageValueProps } from './value-props';

const reviewStats = <div>4,200+ reviews, 4.89/5 rating</div>;

export const reviewStatsLineBreak = (
  <div>
    4,200+ reviews, <br /> 4.89/5 rating
  </div>
);

export const FindYourBestMortgageHeadlineReview = () => (
  <HeadlineReview
    quote="They have better rates and they make the process ridiculously easy!"
    attribution="Alan M."
    caption={reviewStats}
  />
);

const LargeHeadlineWrapper = styled.div`
  ${buildMediaQueries('smallAndDown', {
    display: 'none'
  })}
`;

const SmallHeadlineWrapper = styled.div`
  ${buildMediaQueries(['medium', 'large'], {
    display: 'none'
  })}
`;

const responsiveReviewStats = (
  <>
    <LargeHeadlineWrapper>{reviewStats}</LargeHeadlineWrapper>
    <SmallHeadlineWrapper>{reviewStatsLineBreak}</SmallHeadlineWrapper>
  </>
);

export const FindYourBestMortgageReviews = () => {
  const reviewsData = findYourBestMortgageReviews.map(({ image, quote, attribution }) => ({
    Image: images[image],
    quote,
    attribution
  }));

  return <GroupedReviews headline={responsiveReviewStats} reviewsData={reviewsData} />;
};

const ConditionalTopGraphicWrapper = styled('div')`
  ${buildMediaQueries('smallAndUp', {
    display: 'none'
  })}
`;

// only used in xsmall
const ConditionalTopGraphic = () => (
  <ConditionalTopGraphicWrapper>
    <img
      src={TopGraphicSVG}
      alt="" // decorative
      style={{ margin: '20px 0 20px 0' }}
      height="9px"
    />
  </ConditionalTopGraphicWrapper>
);

const Torso = createOwnUpComponent('div', {
  flexDirection: 'column',
  alignItems: 'center',
  display: 'flex',
  backgroundColor: colors.WHITE,
  paddingBottom: '50px',
  paddingTop: '80px',
  width: '100%',
  variants: {
    smallAndDown: {
      paddingTop: '20px'
    }
  }
});

// ...it controls the width of the torso, what else are you going to call it? ¯\_(ツ)_/¯
const Corset = createOwnUpComponent('div', {
  flexDirection: 'inherit',
  alignItems: 'inherit',
  display: 'inherit',
  variants: {
    large: {
      maxWidth: '1000px',
      margin: '0 128px'
    },
    medium: {
      maxWidth: '580px',
      margin: '0 128px'
    },
    small: {
      maxWidth: '527px',
      margin: '0 56px'
    },
    xsmall: {
      maxWidth: '90%'
    }
  }
});

export const FindYourBestMortgageTorso = () => (
  <section aria-label="Why own up?">
    <Torso>
      <Corset>
        <ConditionalTopGraphic />
        <FindYourBestMortgageHeadlineReview />
        <SquiggleDivider />
        <FindYourBestMortgageValueProps />
        <div style={{ padding: '20px 0 50px' }}>
          <SquiggleDivider />
        </div>
        <FindYourBestMortgageReviews />
      </Corset>
    </Torso>
  </section>
);

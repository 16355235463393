import { ValueProps } from '@rategravity/marketing-components';
import {
  createOwnUpComponent,
  createOwnUpStyle
} from '@rategravity/own-up-component-library-legacy';
import { OwnUpTextStyle } from '@rategravity/own-up-component-library-legacy/components/typography/text/style';
import React from 'react';
import { findYourBestMortgageValueProps } from '../../../data/content/find-your-best-mortgage-mktg02';
import { metadata } from '../../../data/metadata/images';
import { SubheadingStyle } from '../../typography/icon-landing';

const valuePropsStyle = createOwnUpStyle({
  flexDirection: 'column',
  alignItems: 'center',
  display: 'flex',
  maxWidth: '580px'
});

// styling with css selectors doesn't work when passed directly to ValueProps
const ValuePropsWrapper = createOwnUpComponent('div', {
  // header
  '& section > div:nth-child(2) > span': { ...SubheadingStyle },
  // body
  '& section > div:nth-child(3) > span': { ...OwnUpTextStyle, lineHeight: '1.6em' }
});

export const FindYourBestMortgageValueProps = () => (
  <ValuePropsWrapper>
    <ValueProps
      valueProps={findYourBestMortgageValueProps}
      metadata={metadata}
      style={valuePropsStyle}
    />
  </ValuePropsWrapper>
);

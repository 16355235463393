interface Metadata {
  alt: string;
}

export const metadata: Record<string, Metadata> = {
  'chat.svg': {
    alt: ''
  },
  'column-building.svg': {
    alt: ''
  },
  'handshake.svg': {
    alt: ''
  },
  'percent.svg': {
    alt: ''
  },
  'piggy-bank.svg': {
    alt: ''
  },
  'prospective-borrower.svg': {
    alt: ''
  },
  'scale.svg': {
    alt: ''
  },
  'time-is-money.svg': {
    alt: ''
  },
  'website.svg': {
    alt: ''
  },
  'x.svg': {
    alt: ''
  }
};

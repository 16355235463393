import { createOwnUpComponent, OwnUpBox } from '@rategravity/own-up-component-library-legacy';
import React from 'react';
import { CSSObject } from 'styled-components';

export type ImageWithBackgroundProps = {
  Image: JSX.Element;
  backgroundImagePath: string;
  wrapperStyle?: CSSObject;
};

const BackgroundContainer = createOwnUpComponent(OwnUpBox, {
  margin: 'auto',
  backgroundSize: '105%',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  textAlign: 'center',
  smallAndDown: {
    backgroundSize: 'cover'
  },
  width: '100%',
  '& img': {
    borderRadius: '50%'
  }
});

export const ImageWithBackground = ({
  Image,
  backgroundImagePath,
  wrapperStyle = {}
}: ImageWithBackgroundProps) => {
  return (
    <BackgroundContainer
      style={{ backgroundImage: `url(${backgroundImagePath})`, ...wrapperStyle }}
    >
      {Image}
    </BackgroundContainer>
  );
};

import { createOwnUpComponent } from '@rategravity/own-up-component-library-legacy';
import { OwnUpTextStyle } from '@rategravity/own-up-component-library-legacy/components/typography/text/style';
import React from 'react';
import { ReviewProps } from '../../../data/content/find-your-best-mortgage-mktg02/reviews';
import { FiveStars } from '../../reviews/review-with-image';
import { Attribution } from '../../reviews/review-with-image';
import { QuoteStyle } from '../../typography/icon-landing';

const HeadlineReviewWrapper = createOwnUpComponent('div', {
  flexDirection: 'column',
  alignItems: 'center',
  display: 'flex',
  maxWidth: '580px',
  textAlign: 'center',
  '& > *:not(:last-child)': {
    marginBottom: '20px'
  }
});

const Quote = createOwnUpComponent('div', {
  ...QuoteStyle,
  textAlign: 'center',
  ':before': {
    content: '"“"'
  },
  ':after': {
    content: '"”"'
  },
  variants: {
    xsmall: {
      maxWidth: '90%'
    }
  }
});

export const Stats = createOwnUpComponent('div', OwnUpTextStyle);

interface HeadLineReviewProps extends ReviewProps {
  caption: JSX.Element;
}

export const HeadlineReview = ({ quote, attribution, caption }: HeadLineReviewProps) => (
  <HeadlineReviewWrapper>
    <Quote>{quote}</Quote>
    <Attribution>{attribution}</Attribution>
    <FiveStars />
    <Stats>{caption}</Stats>
  </HeadlineReviewWrapper>
);

import { buildMediaQueries } from '@rategravity/marketing-components';
import { createOwnUpComponent } from '@rategravity/own-up-component-library-legacy';
import React from 'react';
import styled from 'styled-components';
import { HeaderMixin } from '../../typography/icon-landing';
import { ReviewWithImage, ReviewWithImageProps } from '../review-with-image';

const GroupedReviewsHeadline = styled.div`
  ${HeaderMixin};
  margin-bottom: 50px;
  text-align: center;
  ${buildMediaQueries('medium', {
    fontSize: '37px',
    lineHeight: '45px'
  })}
  ${buildMediaQueries('smallAndDown', {
    fontSize: '30px',
    lineHeight: '40px',
    marginBottom: '20px'
  })}
`;

const GroupedReviewsWrapper = createOwnUpComponent('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  variants: {
    mediumAndDown: {
      flexDirection: 'column',
      alignItems: 'center'
    },
    mediumAndUp: {
      marginBottom: '20px'
    }
  }
});

export const GroupedReviews = ({
  headline,
  reviewsData
}: {
  headline: JSX.Element;
  reviewsData: ReviewWithImageProps[];
}) => {
  const reviews = reviewsData.map(({ Image, quote, attribution }, i) => (
    <ReviewWithImage {...{ Image, quote, attribution }} key={i} />
  ));

  return (
    <>
      <GroupedReviewsHeadline>{headline}</GroupedReviewsHeadline>
      <GroupedReviewsWrapper>{reviews}</GroupedReviewsWrapper>
    </>
  );
};
